<template>
  <Column :class="$viewport.desktop ? 'grid-6' : ''"
          class="agencies-guides__agency">
    <Column class="agencies-guides__list-filter">
      <SelectComponent id="cityId"
                       label="Выберите населенный пункт"
                       itemKey="name"
                       :index="$operators.cityId"
                       v-on:change-index="changeIndexCity"
                       :array="[{id: '', name: 'Все', slug: 'region', }, ...$cities.data]"/>
      <div v-if="$viewport.desktop"
           class="agencies-guides__list-filter-count">
        Результатов: {{$operators.places.length}}
      </div>
    </Column>
    <Row v-for="(item, index) in $operators.places"
         :key="index"
         class="agencies-guides__row"
         justify="between">
      <Column :class="$viewport.desktop ? 'grid-4' : 'agencies-guides__meta-info'">
        <div class="agencies-guides__list-item"
             v-on:click.self="showAgency(item.id)">
          <p class="agencies-guides__list-item-title"
             v-on:click.self="showAgency(item.id)">{{item.title}}</p>
          <p class="agencies-guides__list-item-address"
             v-on:click.self="showAgency(item.id)">{{item.address}}</p>
          <div class="agencies-guides__flex-row">
            <Rating :params="{id: item.id,
                            type: 'agencies',
                            rating: item.rating,
                            currentUserRating: item.currentUserRating}"/>
          </div>
        </div>
      </Column>
      <Column :class="$viewport.desktop ? 'grid-2' : ''"
              v-on:click.native="showAgency(index)">
        <div class="agencies-guides__list-item-logotype">
          <img class="agencies-guides__list-item-image"
               :src="item.logo || item.image || item.images[0]"
               alt/>
        </div>
      </Column>
    </Row>
  </Column>
</template>

<script>
export default {
  name: 'AgenciesGuidesAgencies',
  created() {
    this.$store.dispatch('GET_AGENCIES_FROM_SERVER_TO_OPERATORS');
  },
  methods: {
    changeIndexCity(id) {
      this.$store.commit('SET_CITY_ID_IN_OPERATORS', id);
    },
    showAgency(id) {
      this.$store.commit('SET_AGENCY_TO_OPERATORS', id);
    },
  },
};
</script>
